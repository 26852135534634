import React, { useContext, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';

import MapContext from '../context/map';
import Portal from '../Portal';

const Marker = ({ center, children, options }) => {
  const map = useContext(MapContext);

  const [container] = useState(document.createElement('div'));
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (map) {
      // if (marker) {
      //   marker.setLngLat(center);
      // } else {
      if (center) {
        const m = new mapboxgl.Marker({ element: container, ...options });
        m.setLngLat(center).addTo(map);
        setMarker(m);
      }
      // }

      return () => marker && marker.remove();
    }
  }, [center, map]);

  return (
    <Portal container={container}>
      {children}
    </Portal>
  );
}

export default Marker;
