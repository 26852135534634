import React, { useContext } from 'react';
import VenuesContext from '../context/venues';
import Marker from './Marker';

const VenueIcons = () => {
  const { venues } = useContext(VenuesContext);

  if (venues) {
    return (
      <>
        {Object.keys(venues).map(type =>
          venues[type].venues.map(({ location }) => (
            <Marker center={[location.lng, location.lat]}>
              <img src={venues[type].icon} />
            </Marker>
          ))
        )}
      </>
    );
  }

  return null;
}

export default VenueIcons;