import React from 'react';
import { Box } from '@material-ui/core';

import SidebarHeader from './Header';
import SidebarBody from './Body';

const Sidebar = () => {
  return (
    <Box style={{ width: 361 }} display="flex" flexDirection="column">
      <SidebarHeader />
      <SidebarBody />
    </Box>
  );
}

export default Sidebar;
