import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Box, Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import distance from '@turf/distance';

import { KeyboardArrowRight, KeyboardArrowDown } from '@material-ui/icons';

import mapPin from './map-pin.svg';
import telescope from './telescope.svg';
import fileExclamation from './file-exclamation.svg';

import AddressContext from '../context/address';
import venuesJSON from '../venues.json';
import VenuesContext from '../context/venues';
import RadiusContext from '../context/radius';

const useStyles = makeStyles({
  searchPadding: {
    paddingLeft: 30,
  },
  resultCategory: {
    fontFamily: "'Inter', sans-serif",
    fontWeight: 900,
    fontSize: 14,
    paddingTop: 13,
    paddingBottom: 13,
  },
  inter700: {
    fontFamily: "'Inter' sans-serif",
    fontWeight: 700,
    fontSize: 12,
  },
  inter400: {
    fontFamily: "'Inter' sans-serif",
    fontWeight: 400,
  },
  venuePrimary: {
    fontFamily: "'Inter' sans-serif",
    fontWeight: 700,
    fontSize: 10,
  },
  venueSecondary: {
    fontFamily: "'Inter' sans-serif",
    fontWeight: 400,
    fontSize: 10,
    color: 'unset',
  },
});

const SidebarBody = () => {
  const classes = useStyles();
  const { address } = useContext(AddressContext);
  const { radius } = useContext(RadiusContext);
  const { setVenues } = useContext(VenuesContext);

  const [propertyProperties, setPropertyProperties] = useState(null);
  const [openCategories, setOpenCategories] = useState(null);

  const toggleCategory = (type) => {
    setOpenCategories({ ...openCategories, [type]: !openCategories[type] });
  };

  useEffect(() => {
    if (address) {
      setVenues(null);
      setPropertyProperties(null);

      const calls = venuesJSON.map(venue => {
        const params = new URLSearchParams();

        params.append('client_id', process.env.REACT_APP_FOURSQUARE_CLIENT_ID);
        params.append('client_secret', process.env.REACT_APP_FOURSQUARE_CLIENT_SECRET);
        params.append('ll', [...address.center].reverse().toString());
        params.append('radius', (radius / 0.00062137).toString());
        params.append('v', '20200901');
        params.append('categoryId', venue.code);
        params.append('limit', '50');

        return fetch(`https://api.foursquare.com/v2/venues/search?${params.toString()}`);
      });

      Promise
        .all(calls)
        .then(responses => Promise.all(responses.map(r => r.json())))
        .then(r => {
          const venuesTemp = [...venuesJSON].reduce((acc, { type, icon }) => (acc[type] = { icon }) && acc, {});

          r.forEach(({ response }, i) => (venuesTemp[venuesJSON[i].type].venues = response.venues.filter(({ location }) => distance(address.center, [location.lng, location.lat], { units: 'miles' }).toFixed(2) < radius)));

          const propertyProperties = { concerns: [], highlights: [] };
          Object
            .keys(venuesTemp)
            .forEach(async (type, i) => {
              const { venues, icon } = venuesTemp[type];
              const value = { type, venues, icon: (await import(`../assets/svg/${icon}`)).default };
              venuesTemp[type].icon = value.icon;

              if (type.toLowerCase().includes('caution') || type.toLowerCase().includes('concerns')) {
                propertyProperties.concerns.push(value);
              } else {
                propertyProperties.highlights.push(value);
              }

              if (i === Object.keys(venuesTemp).length - 1) {
                setPropertyProperties(propertyProperties);
                setVenues(venuesTemp);
              }
            });

          // setVenues(venuesTemp);
          setOpenCategories(venuesJSON.reduce((acc, { type }) => (acc[type] = true) && acc, {}));
        });
    }
  }, [address, radius]);

  return (
    <Switch>
      <Route path="/search/:id">
        <Box style={{ flex: 1, overflowY: 'auto' }}>
          {propertyProperties &&
            [
              { primary: '#F2ABAB', secondary: '#C66464', tertiary: '#D47878', title: 'concerns', categories: propertyProperties.concerns },
              { primary: '#B9E1F8', secondary: '#5597BD', tertiary: '#40A6E0', title: 'highlights', categories: propertyProperties.highlights },
            ].map(({ primary, tertiary, categories, secondary, title }) => (
              <Box>
                <Box
                  className={`${classes.searchPadding} ${classes.resultCategory}`}
                  style={{
                    backgroundColor: primary,
                    color: secondary,
                  }}
                >
                  <span style={{ textTransform: 'uppercase' }}>property {title}</span>
                </Box>
                <Box>
                  {categories.map(({ type, venues, icon }) => {
                    if (venues.length) {
                      return (
                        <>
                          <ListItem
                            onClick={() => toggleCategory(type)}
                            className={classes.searchPadding}
                            button
                            dense
                          >
                            <ListItemIcon style={{ minWidth: 22 }}>
                              <img src={icon} style={{ width: 16, height: 16 }} />
                            </ListItemIcon>
                            <ListItemText
                              primary={`${venues.length} ${type} Nearby`}
                              style={{ color: tertiary }}
                              classes={{ primary: classes.inter700 }}
                            />
                            {openCategories[type] ? <KeyboardArrowDown style={{ color: '#D1D1D1' }} /> : <KeyboardArrowRight style={{ color: '#D1D1D1' }} />}
                          </ListItem>
                          <Collapse in={openCategories[type]}>
                            <List
                              disablePadding
                              dense
                              className={classes.searchPadding}
                              style={{ marginRight: 22 }}
                            >
                              {
                                venues.map(({ name, location }) => (
                                  <ListItem disableGutters>
                                    <Box>
                                      <div className={classes.venuePrimary}>
                                        {name}
                                      </div>
                                      <div className={classes.venueSecondary}>
                                        <span style={{ paddingRight: 5, borderRight: '1px solid black' }}>{`${+distance(address.center, [location.lng, location.lat], { units: 'miles' }).toFixed(2)} miles away`}</span>
                                        <span style={{ paddingLeft: 5 }}>{`${location.address || location.formattedAddress.join(' ')}`}</span>
                                      </div>
                                    </Box>
                                  </ListItem>
                                ))
                              }
                            </List>
                          </Collapse>
                        </>
                      )
                    }
                    return null;
                  })}
                </Box>
              </Box>
            ))}
        </Box>
      </Route>
      <Route path="/">
        <Box
          style={{ backgroundColor: '#505367', color: 'white', padding: 55 }}
          flexGrow={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {[
            { icon: mapPin, text: 'Our snapshots give you an overview of a property’s highlights and concerns overlaid visually on a map.' },
            { icon: telescope, text: 'Discover any hidden issues or peaks of the property’s location early in your research process.' },
            { icon: fileExclamation, text: 'Save the headache of finding disclosure surprises last minute during escrow or even after your purchase.' },
          ].map(({ icon, text }) => (
            <Fragment key={text}>
              <img style={{ marginBottom: 10 }} src={icon} />
              <span
                style={{
                  fontFamily: "'Asap', sans-serif",
                  fontStyle: 'italic',
                  textAlign: 'center',
                  marginBottom: 25,
                  height: 80
                }}
              >
                {text}
              </span>
            </Fragment>
          ))}

        </Box>
      </Route>
    </Switch>
  );
}

export default SidebarBody;