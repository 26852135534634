import { useContext, useEffect } from 'react';
import circle from '@turf/circle';
import bbox from '@turf/bbox';

import AddressContext from '../context/address';
import MapContext from '../context/map';
import RadiusContext from '../context/radius';

const fitRadar = (map, radar) => map.fitBounds(bbox(radar), { padding: 50 });
const setRadarData = (map, geojson) => map.getSource('radar').setData(geojson);

const RadarLayer = () => {
  const map = useContext(MapContext);
  const { address } = useContext(AddressContext);
  const { radius } = useContext(RadiusContext);

  useEffect(() => {
    if (address && map) {
      const radar = circle(address.center, radius, { units: 'miles' });

      if (map.getSource('radar')) {
        setRadarData(map, radar);
      } else {
        map.addSource('radar', {
          type: 'geojson',
          data: radar,
        });

        map.addLayer({
          id: 'radar',
          type: 'line',
          source: 'radar',
          layout: {},
          paint: {
            'line-color': '#AFC0E9',
            'line-width': 3,
          }
        });
      }

      fitRadar(map, radar);

      return () => {
        // Cleanup radar layer
        map.removeLayer('radar');
        map.removeSource('radar');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, map, radius])

  return null;
}

export default RadarLayer;