import React, { createContext, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";

const mapContext = createContext();

export const MapProvider = ({ children }) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: 'map-container',
        style: 'mapbox://styles/theloco/ckdpajuzf00zr1imw4uejkaqe',
        attributionControl: false,
        logoPosition: 'bottom-right',
        center: [-117.969, 34.005],
        zoom: 9,
        pitch: 20,
      });

      map.on("load", () => {
        setMap(map);
        map.resize();
      });
    };

    if (!map) initializeMap({ setMap });
  }, [map]);

  return (
    <mapContext.Provider value={map}>
      { children}
    </mapContext.Provider>
  );
}

export default mapContext;
