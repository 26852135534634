import React from 'react';
import { Box } from '@material-ui/core';

import SidebarSearch from './Search';

const SidebarHeader = () => {
  return (
    <Box
      style={{
        height: 205,
        padding: '12px 18px',
        backgroundColor: '#3F4153',
        fontFamily: "'Inter', sans-serif"
      }}
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
    >
      <div style={{ fontSize: 36, fontWeight: 900 }}>
        <span style={{ color: '#F07272' }}>Circa</span>
        <span style={{ color: 'white' }}>Maps</span>
      </div>
      <div
        style={{
          color: 'white',
          fontSize: '14px !important',
          marginBottom: 19
        }}
      >
        Get a snapshot of any property in seconds
      </div>
      <SidebarSearch />
    </Box>
  );
}

export default SidebarHeader;
