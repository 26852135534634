import React, { useContext, useEffect } from 'react';
import AddressContext from '../context/address';

import Marker from './Marker';
import homeMarker from './home-marker.svg';

const AddressMarker = () => {
  const { address } = useContext(AddressContext);

  useEffect(() => {
    console.log(address && address.center);
  }, [address]);

  if (address) {
    return (
      <Marker center={address.center} options={{ offset: [0, -28] }}>
        <img src={homeMarker} />
      </Marker>
    )
  }

  return null;
}

export default AddressMarker;
