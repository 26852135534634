import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box } from '@material-ui/core';

import Sidebar from './Sidebar';
import Map from './Map';
import { AddressProvider } from './context/address';
import { RadiusProvider } from './context/radius';
import { VenuesProvider } from './context/venues';

function App() {
  return (
    <Router>
      <Box
        style={{ height: '100vh' }}
        display="flex"
      >
        <RadiusProvider>
          <AddressProvider>
            <VenuesProvider>
              <Sidebar />
              <Map />
            </VenuesProvider>
          </AddressProvider>
        </RadiusProvider>
      </Box>
    </Router>
  );
}

export default App;
