import React, { useRef, useContext, useEffect } from 'react';

import MapContext from '../context/map';
import Portal from '../Portal';
import mapboxgl from 'mapbox-gl';

const MapControl = ({ children, position }) => {
  const controlContainer = useRef();
  const map = useContext(MapContext);

  const control = {
    onAdd() {
      return controlContainer.current;
    },
    onRemove() {
      // TODO
    }
  }

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    map?.addControl(control, position);

    return () => map?.removeControl(control);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  return (
    <div ref={el => controlContainer.current = el} className="mapboxgl-ctrl">
      {
        controlContainer.current
        && (
          <Portal container={controlContainer.current}>
            {children}
          </Portal>
        )
      }
    </div>
  );
}

export default MapControl;