import React, { useContext, useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core';

import MapControl from './MapControl';
import mapContext from '../context/map';
import { Map } from 'mapbox-gl';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2f3141'
    },
  },
});

const useStyles = makeStyles({
  label: {
    fontSize: 16,
    fontFamily: "'IBM Plex Sans', sans-serif",
  },
  labelSelected: {
    fontSize: 16,
    fontFamily: "'IBM Plex Sans', sans-serif",
    fontWeight: 700,
  }
});

const MapLayerControl = () => {
  const map = useContext(mapContext);
  const [layerGroups, setLayerGroups] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (map) {
      const layers = map.style._layers;
      const layerGroupsTemp = {};

      for (const layerID in layers) {
        const { metadata } = layers[layerID];

        if (metadata && metadata.layerControl) {
          const uuid = metadata['mapbox:group'];

          if (layerGroupsTemp[uuid]) {
            layerGroupsTemp[uuid].layers.push(layerID);
          } else {
            layerGroupsTemp[uuid] = { layers: [layerID], visible: false };
          }

          map.setLayoutProperty(layerID, 'visibility', 'none');
        }
      }

      const groupMetadata = map.style.stylesheet.metadata['mapbox:groups'];

      for (const uuid in layerGroupsTemp) {
        if (uuid in groupMetadata) {
          layerGroupsTemp[uuid].name = groupMetadata[uuid].name;
        }
      }

      setLayerGroups(layerGroupsTemp);
    }
  }, [map])

  const handleLayerToggle = (uuid) => {
    layerGroups[uuid].layers.forEach((layerID) => {
      if (layerGroups[uuid].visible) {
        map.setLayoutProperty(layerID, 'visibility', 'none');
      } else {
        map.setLayoutProperty(layerID, 'visibility', 'visible');
      }
    });

    setLayerGroups({ ...layerGroups, [uuid]: { ...layerGroups[uuid], visible: !layerGroups[uuid].visible } })
  };

  return (
    <MapControl position="top-right">
      <Box
        style={{
          backgroundColor: 'white',
          borderRadius: 12,
          boxShadow: '0px 8px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px 2px rgba(97, 97, 97, 0.1)',
          padding: '0 20px',
        }}
      >
        <ThemeProvider theme={theme}>
          <>
            {layerGroups && Object.keys(layerGroups).map((uuid) => (
              <Box key={uuid}>
                <FormControlLabel
                  control={<Checkbox checked={layerGroups[uuid].visible} onChange={() => handleLayerToggle(uuid)} name={layerGroups[uuid].name} color="primary" />}
                  label={layerGroups[uuid].name}
                  classes={{ label: layerGroups[uuid].visible ? classes.labelSelected : classes.label }}
                />
              </Box>
            ))}
          </>
        </ThemeProvider>
      </Box>
    </MapControl>
  );
}

export default MapLayerControl;
