import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

const ControlPortal = ({ container, children }) => {
  const [el] = useState(document.createElement('div'));

  useEffect(() => {
    container.appendChild(el);

    return () => container.removeChild(el);
  }, [container, el]);

  return (
    createPortal(
      children,
      el,
    )
  );
}

export default ControlPortal;
