import React from 'react';

import Container from './Container';
import LayerControl from './LayerControl';
import { MapProvider } from '../context/map';
import AddressMarker from './AddressMarker';
import RadarLayer from './RadarLayer';
import VenueIcons from './VenueIcons';
import RadiusSlider from './RadiusSlider';

const Map = () => {

  return (
    <MapProvider>
      <Container />
      <LayerControl />
      <AddressMarker />
      <RadarLayer />
      <VenueIcons />
      <RadiusSlider />
    </MapProvider>
  );
}

export default Map;