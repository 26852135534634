import React, { useCallback } from 'react';
import Slider from '@material-ui/core/Slider';

import MapControl from './MapControl';
import RadiusContext from '../context/radius';
import { useContext } from 'react';
import AddressContext from '../context/address';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2E3041',
    }
  }
});

const RadiusSlider = () => {
  const { radius, setRadius } = useContext(RadiusContext);
  const { address } = useContext(AddressContext);

  const debouncedSetRadius = useCallback(() => {
    let timeout;

    return;
  }, [setRadius]);

  const handleSliderChange = (event, newValue) => {
    setRadius(newValue);
  }

  return (
    <MapControl position='bottom-left'>
      {
        address
        && (
          <div
            style={{
              height: '32px',
              padding: '0 10px',
              width: '231px',
              backgroundColor: 'white',
              borderRadius: '12px',
              border: `2px solid lightgray`,
            }}
          >
            <ThemeProvider theme={theme}>
              <Slider
                value={radius}
                onChange={handleSliderChange}
                min={1}
                max={15}
                color="primary"
                valueLabelDisplay="on"
                valueLabelFormat={value => `${value}mi`}
                aria-labelledby="continuous-slider"
              />
            </ThemeProvider>
          </div>
        )
      }
    </MapControl>
  );
}

export default RadiusSlider;