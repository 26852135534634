import React, { createContext, useState } from 'react';

const RadiusContext = createContext();

export const RadiusProvider = ({ children }) => {
  const [radius, setRadius] = useState(1);

  const value = {
    radius,
    setRadius,
  };

  return (
    <RadiusContext.Provider value={value}>
      { children}
    </RadiusContext.Provider>
  );
}

export default RadiusContext;
