import { createContext, useState } from 'react';

const VenuesContext = createContext();

export const VenuesProvider = ({ children }) => {
  const [venues, setVenues] = useState(null);

  const value = {
    venues,
    setVenues,
  };

  return (
    <VenuesContext.Provider value={value}>
      { children}
    </VenuesContext.Provider>
  );
}

export default VenuesContext;
