import React, { useContext, useEffect, useState } from 'react';
import { Input } from '@material-ui/core';

import AddressContext from '../context/address';
import RadiusContext from '../context/radius';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router';

const url = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
const params = new URLSearchParams({ access_token: process.env.REACT_APP_MAPBOX_TOKEN });

const SidebarSearch = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { setAddress } = useContext(AddressContext);
  const [localAddress, setLocalAddress] = useState('');

  const setAllAdresses = (feature) => {
    setAddress(feature);
    setLocalAddress(feature.place_name);
  }

  useEffect(() => {
    if (pathname.startsWith('/search')) {
      const name = pathname.replace('/search/', '');
      fetch(`${url}/${name}.json?${params.toString()}`)
        .then(res => res.json())
        .then(({ features }) => {
          const [feature] = features;
          const { place_name } = feature;

          if (place_name === name) {
            setAllAdresses(feature)
          }
        });
    }
  }, []);

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    const locationURL = `${encodeURI(localAddress)}.json`;

    fetch(`${url}/${locationURL}?${params.toString()}`)
      .then(res => res.json())
      .then(({ features }) => {
        if (features) {
          const [feature] = features;
          const [placeType] = feature.place_type;

          if (placeType === 'address') {
            setAllAdresses(feature);
            push(`/search/${feature.place_name}`);
          } else {
            setAddress(null);
          }
        } else {
          setAddress(null);
        }
      });
  };

  return (
    <form onSubmit={handleSearchSubmit}>
      <Input
        style={{
          backgroundColor: 'white',
          border: '1px sold black',
          borderRadius: 7,
          padding: '0 13px',
          fontSize: 14,
          fontFamily: "'DM Sans', sans-serif"
        }}
        value={localAddress}
        onInput={(e) => setLocalAddress(e.target.value)}
        placeholder="Enter a property's address to get started"
        disableUnderline
        fullWidth
      />
    </form>
  );
}

export default SidebarSearch;
