import { createContext, useState } from 'react';

const AddressContext = createContext();

export const AddressProvider = ({ children }) => {
  const [address, setAddress] = useState(null);

  const value = {
    address,
    setAddress,
  };

  return (
    <AddressContext.Provider value={value}>
      { children}
    </AddressContext.Provider>
  );
}

export default AddressContext;
